import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/SKKvpcjAfEI">
    <SEO title="Why Do We Give? - Why Church?" />
  </Layout>
)
export default SermonPost
